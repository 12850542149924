<style lang="scss" scoped>
 


.mainHeading{
    font-size: 6rem;
   text-align: center;
 
}


     #diamond {
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-bottom-color: red;
      position: relative;
      top: -50px;
  
  transform: scale(3);
  transition: transform 0.25s ease 0.1s;

    }
    #diamond:after {
      content: '';
      position: absolute;
      left: -50px;
      top: 50px;
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-top-color: red;
    }



</style>
<template>

<Animation3 />

 

<div id="teams" class="page" style="background: black">

 <div class="flex h-screen justify-center items-center">
        <div id="diamond" class="flex h-screen justify-center items-center" >
           <WhiteLogo />
        </div>
 </div>



</div>


 

    
</template>

<script>

import WhiteLogo from "@/assets/images/anim_logo_white.svg";
 
import Animation3 from "@/components/Animation3";


export default {
  
  
  components:{
    WhiteLogo,
 
    Animation3
  }

 
}
</script>
 

